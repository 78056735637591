import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { animated, useSpring, config } from 'react-spring'
import Layout from '../components/layout'
import GridItem from '../components/grid-item'
import SEO from '../components/SEO'
import { ChildImageSharp } from '../types'

type PageProps = {
  data: {
    firstProject: {
      title: string
      slug: string
      cover: ChildImageSharp
    }
    threeProjects: {
      nodes: {
        title: string
        slug: string
        cover: ChildImageSharp
      }[]
    }
    aboutUs: ChildImageSharp
    instagram: ChildImageSharp
  }
}

const Area = styled(animated.div)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 35vh 40vh 25vh;
  grid-template-areas:
    'first-project about-us about-us'
    'three-projects three-projects three-projects'
    'writing writing instagram';

  @media (max-width: ${props => props.theme.breakpoints[3]}) {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 35vh 35vh 30vh;

    grid-template-areas:
      'first-project first-project about-us about-us'
      'three-projects three-projects three-projects three-projects'
      'writing writing instagram instagram';
  }

  @media (max-width: ${props => props.theme.breakpoints[1]}) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(5, 38vw);

    grid-template-areas:
      'first-project about-us'
      'three-projects three-projects'
      'three-projects three-projects'
      'three-projects three-projects'
      'writing instagram';
  }

  @media (max-width: ${props => props.theme.breakpoints[0]}) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(7, 50vw);

    grid-template-areas:
      'first-project'
      'about-us'
      'three-projects'
      'three-projects'
      'three-projects'
      'writing'
      'instagram';
  }
`

const FirstProject = styled(GridItem)`
  grid-area: first-project;
`

const AboutUs = styled(GridItem)`
  grid-area: about-us;
`

const ThreeProjects = styled.div`
  grid-area: three-projects;
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: ${props => props.theme.breakpoints[1]}) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }
`

const Instagram = styled(GridItem)`
  grid-area: instagram;
`

const Writing = styled(GridItem)`
  grid-area: writing;
`


const ProjectImg = styled(Img)`
  filter: brightness(75%); 
  -webkit-filter: brightness(75%); 
  :hover {
    filter: brightness(95%); 
    -webkit-filter: brightness(95%); 
  }
`

const ProjectImg2 = styled.img`
  filter: brightness(75%); 
  -webkit-filter: brightness(75%); 
  :hover {
    filter: brightness(95%); 
    -webkit-filter: brightness(95%); 
  }
`

const PButton = styled.button`
    cursor: pointer;
    display: inline-block;
    background-color: white;
    position: absolute; 
    z-index: 10;
    top: 15px;
    right: 15px;
    width: auto;
    margin: auto;
    text-align: right;
    font-weight: 600;
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    background-opacity: 80%;
    font-size: ${props => props.theme.fontSizes[2]};
    padding: ${props => props.theme.space[3]};

    @media (max-width: ${props => props.theme.breakpoints[3]}) {
      font-size: ${props => props.theme.fontSizes[1]};
      padding: ${props => props.theme.space[2]};
    }
`

const Index: React.FunctionComponent<PageProps> = ({ data: { firstProject, threeProjects, aboutUs, instagram, allNotion } }) => {
  const pageAnimation = useSpring({
    config: config.slow,
    from: { opacity: 0 },
    to: { opacity: 1 },
  })

  return (
    <Layout>
      <SEO />
      <Area style={pageAnimation}>
        <FirstProject to={firstProject.slug} aria-label={`View project "${firstProject.title}"`}>
          <ProjectImg fluid={firstProject.cover.childImageSharp.fluid} />
          <PButton>{firstProject.title}</PButton>
        </FirstProject>
        <AboutUs to="/about/" aria-label="Visit my about page">
          <ProjectImg fluid={aboutUs.childImageSharp.fluid} />
          <PButton>About Me</PButton>
        </AboutUs>
        <ThreeProjects>
          {threeProjects.nodes.map(project => (
            <GridItem to={project.slug} key={project.slug} aria-label={`View project "${project.title}"`}>
              <ProjectImg fluid={project.cover.childImageSharp.fluid} />
              <PButton>{project.title}</PButton>
            </GridItem>
          ))}
        </ThreeProjects>
        <Writing to={`/blog/posts/${allNotion.edges[0].node.childMdx.frontmatter.url}/`} aria-label="Read My Latest Sharing">
          <ProjectImg className="post-card-img"
            fluid={allNotion.edges[0].node.localImage.childImageSharp.fluid} 
          />
          <PButton>Latest Sharing</PButton>
        </Writing>
        <Instagram to="/thex3family" aria-label="Join the Co-x3 Community">
          <ProjectImg fluid={instagram.childImageSharp.fluid} />
          <PButton>Our Community</PButton>
        </Instagram>
      </Area>
    </Layout>
  )
}

export default Index

export const query = graphql`
  query Index {
    firstProject: projectsYaml {
      title
      slug
      cover {
        childImageSharp {
          fluid(quality: 95, maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    threeProjects: allProjectsYaml(limit: 3, skip: 1) {
      nodes {
        title
        slug
        cover {
          childImageSharp {
            fluid(quality: 95, maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    aboutUs: file(sourceInstanceName: { eq: "images" }, name: { eq: "me" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    instagram: file(sourceInstanceName: { eq: "images" }, name: { eq: "banner" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    allNotion(
      filter: {properties: {status: {value: {name: {eq: "published"}}}}}
      limit: 1
      sort: {fields: childMdx___frontmatter___publish_date___start, order: DESC}
    ) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 95, maxWidth: 1200) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          childMdx {
            frontmatter {
              title
              tags {
                name
              }
              content_type {
                name
              }
              desc
              status {
                name
              }
              url
              read_time
              publish_date {
                start(fromNow: false, formatString: "YYYY-MMM-DD")
              }
            }
            timeToRead
          }
        }
      }
}
  }
`
